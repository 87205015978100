@layer home {
  .waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px; /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
  }

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-of-type(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-of-type(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-of-type(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-of-type(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }

  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30vh;
    }
    h1 {
      font-size: 24px;
    }
  }

  .second-fold {
    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      height: 100px;
      border-radius: 0 0 50% 50%;
    }
  }

  .fourth-fold {
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0;
      transform: translateX(30%);
      z-index: -1;
      background: url("/images/home/support-ellipse.png") no-repeat;
      width: 310px;
      height: 310px;
    }

    &:after {
      left: 0;
      bottom: 0;
      transform: translateX(-30%);
    }
  }

  .chart-item {
    position: absolute;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    outline: 1px solid #a59d9880;
    background: #fff;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: #a59d9880;
    }
  }

  .rotate-chart {
    animation: rotate 80s linear infinite;
  }
}
