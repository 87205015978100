@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./homepage.css";
@import "./rich-text-editor.css";

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 22 97% 55%;
    --primary-foreground: 0 0% 98%;

    --secondary: 22 24% 25%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 6 61% 49%;
    --destructive-foreground: 0 0% 98%;

    --info: 234 71% 68%;
    --info-foreground: 0 0% 98%;

    --warning: 40 98% 50%;
    --warning-foreground: 0 0% 98%;

    --success: 130 38% 63%;
    --success-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  html {
    font-family: var(--inter);
  }

  html,
  body {
    min-height: 100dvh;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    word-wrap: break-word;
    @apply text-base;

    h2 {
      @apply text-3xl font-medium;
    }

    h3 {
      @apply text-2xl font-medium;
    }

    h4 {
      @apply text-xl font-medium;
    }

    h5 {
      @apply text-lg font-medium;
    }

    h6 {
      @apply text-base font-normal;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    a {
      @apply text-primary;

      &:hover {
        @apply underline;
      }
    }

    blockquote {
      @apply border-l-4 border-accent p-4 my-4;
    }

    ul {
      @apply pl-6 list-disc;

      li {
        @apply my-2;
      }
    }

    ol {
      @apply pl-8 list-none flex flex-col gap-6;
      counter-reset: item;

      li {
        @apply pl-8 relative;

        &::before {
          content: counter(item);
          white-space: pre;
          counter-increment: item;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          background-color: #e1e4fa;
          border-radius: 53px;
          position: absolute;
          left: -1.5em;
          font-size: 22px;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: -0.22px;
          padding: 7.5px 0;
        }
      }
    }
  }

  .animated-button {
    box-shadow: currentColor 0px 0px 0px 0px;
    animation: 2s ease 0s infinite normal none running pulse;
  }

  @keyframes pulse {
    100% {
      box-shadow: rgba(252, 109, 29, 0) 0px 0px 0px 14px;
    }
  }

  [data-scrollable] {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: #ddd transparent;
    scrollbar-gutter: "auto | stable && both-edges?";
  }
}
