@tailwind base;
@tailwind components;
@tailwind utilities;

.rich-text-editor {
  @apply min-h-[200px] w-full rounded-lg border border-input bg-background;
}

.ql-editor {
  @apply space-y-2;
}

.ql-container.ql-snow {
  @apply border-none;
}

.ql-toolbar.ql-snow {
  @apply border-none bg-gray-50;
}

.rich-text-editor .ql-container.ql-bubble:not(.ql-disabled) a::before,
.rich-text-editor .ql-container.ql-bubble:not(.ql-disabled) a::after {
  content: none;
}
